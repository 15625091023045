import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledLocation = styled.div`
  border: 1px solid ${colors.mediumGrey};
  border-radius: 0.5rem;
  p {
    margin: 0;
    font-weight: 300;
  }
  display: grid;
  grid-template-columns: 3fr 1fr;
  overflow: hidden;
  .location-left {
    padding: 1rem;
    .location-name {
      font-weight: 500;
    }
  }
  .location-right {
    border-left: 1px solid ${colors.mediumGrey};
    background: ${colors.green};
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      margin-bottom: 0.2rem;
    }
  }
`

const Location = props => {
  const adress = props.location.adress.split(",")[0]
  const area = props.location.adress.split(",")[1]
  const { location } = props
  return (
    <StyledLocation>
      <div className="location-left">
        <p className="location-name">{location.name}</p>
        <p>{adress}</p>
        <p>{area}</p>
      </div>
      <a
        rel="noreferrer"
        target="_blank"
        href={`https://www.google.com/maps/dir/?api=1&destination=${props.location.adress
          .replace(" ", "+")
          .replace(",", "%2C")}&travelmode=bicycling`}
        className="location-right"
      >
        <img src="/images/map.png" alt="Hitta" />
        Hitta hit
      </a>
    </StyledLocation>
  )
}

export default Location
