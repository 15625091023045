import Axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import H1 from "../components/h1"
import Layout from "../components/layout"
import Narrow from "../components/narrow"
import Loader from "../components/loader"
import Location from "../components/location"
import { colors, db } from "../utils/siteVars"

const StyledHittaPage = styled.div`
  p.narrow {
    max-width: 700px;
    margin: auto;
    margin-bottom: 2rem;
  }
  .loader-container {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .locations-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

// const tempData = [
//   {
//     adress: "Gatugatan 1, 112 12 Bromma",
//     name: "Gatan",
//     country: "Norge",
//   },
//   {
//     adress: "Gatugatan 1, 112 12 Bromma",
//     name: "Gatan",
//     country: "Norge",
//   },
//   {
//     adress: "Gatugatan 1, 112 12 Bromma",
//     name: "Gatan",
//     country: "Norge",
//   },
//   {
//     adress: "Gatugatan 1, 112 12 Bromma",
//     name: "Gatan",
//     country: "Sverige",
//   },
//   {
//     adress: "Gatugatan 1, 112 12 Bromma",
//     name: "Gatan",
//     country: "Sverige",
//   },
// ]

const HittaPage = () => {
  const query = useStaticQuery(graphql`
    query mapQuery {
      image: file(relativePath: { eq: "wash-top.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const [locations, setLocations] = useState({
    sweden: [],
    norway: [],
    comingSoon: [],
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Axios.get(db + "locations")
      .then(res => {
        const tempData = {
          sweden: res.data.filter(loc => {
            return loc.country !== "Norge" && !loc.comingSoon
          }),
          norway: res.data.filter(loc => {
            return loc.country === "Norge" && !loc.comingSoon
          }),
          comingSoon: res.data.filter(loc => {
            return loc.comingSoon
          }),
        }
        console.log(tempData)
        setLocations(tempData)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  return (
    <Layout
      headerImage={query.image.childImageSharp.fluid}
      headerText="Hitta cykeltvätt"
      helmet="Hitta"
    >
      <StyledHittaPage>
        <Narrow>
          <H1 small>Hitta din cykeltvätt</H1>
          <p className="centered narrow">
            Nedan ser du cykeltvättar som finns tillgängliga just nu. Klicka på
            den gröna knappen för att hitta dit, och cykla därifrån med en
            glänsande cykel. Vi jobbar alltid för att få ut fler tvättar till
            kommuner, företag och event.
          </p>
          {loading ? (
            <div className="loader-container">
              <Loader color={colors.mediumGrey} dualRing />
            </div>
          ) : (
            <>
              <H1 fontSize="1.8rem">Tvättar i Sverige</H1>
              <div className="locations-container">
                {locations.sweden.map(location => (
                  <Location location={location} key={location.adress} />
                ))}
              </div>
              <H1 fontSize="1.8rem">
                Tvättar i Norge
                <img src="/images/flag-norway.png" alt="" width="40px" />
              </H1>
              <div className="locations-container">
                {locations.norway.map(location => (
                  <Location location={location} key={location.adress} />
                ))}
              </div>
              {locations.comingSoon.length > 0 ? (
                <>
                  <H1 fontSize="1.8rem">Kommer snart</H1>
                  <div className="locations-container">
                    {locations.comingSoon.map(location => (
                      <Location location={location} key={location.adress} />
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
        </Narrow>
      </StyledHittaPage>
    </Layout>
  )
}

export default HittaPage
